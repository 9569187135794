import axios from "axios";
const { REACT_APP_API_URL } = process.env;

const APIClient = axios.create({
  baseURL: `${REACT_APP_API_URL}/v1/panel`,
});

function setAuthToken(token) {
  APIClient.defaults.headers["Authorization"] = `Bearer ${token}`;
}

function unsetAuthToken() {
  APIClient.defaults.headers.Authorization = "";
}

export { APIClient, setAuthToken, unsetAuthToken };
