import React, { Component } from "react";
import {
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import { Formik, Field, Form } from "formik";

// import Select from "react-select";
// import CustomSelectInput from "../../../../../../components/common/CustomSelectInput";
import IntlMessages from "../../../../../../helpers/IntlMessages";

import { Content, Subscription } from "../../../../../../api/Api";

export default class AddConstant extends Component {
  state = {
    loading: false,
    picture: "",
    data: [],
    file: "",
    src: "فایل",
    contentType: "Podcast",
    subscription: "5fbfd2e43a6ba84a62dce3a4",
    isRecommended: true,
    initialValues: {
      name: "",
    },
  };

  componentDidMount() {
    this.getSubscription();
  }

  getSubscription = async () => {
    try {
      const { data, status } = await Subscription.getAllSubscription();

      let subFree = data.data && data.data.find((x) => x.priority === 0);
      // console.log("subFree", subFree);
      // console.log("data", data);
      this.setState({ data: data.data, subscription: subFree.id });
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (e) => {
    this.setState({
      contentType: e.target.value,
    });
  };
  handleChangeSub = (e) => {
    this.setState({
      subscription: e.target.value,
    });
  };
  handleChangeRec = (e) => {
    this.setState({
      isRecommended: e.target.value,
    });
  };

  handleChangePicture = async ({ target }) => {
    try {
      let picture = target.files[0],
        src = target.value;
      var reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          picture,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(picture);

      this.setState({ picture, src });
    } catch (error) {}
  };

  handleChangeFile = async ({ target }) => {
    try {
      let file = target.files[0],
        src = target.value;
      // console.log("file", file);

      this.setState({ file, src });
    } catch (error) {}
  };
  handleSubmit = async (values, { resetForm, setSubmitting, setValues }) => {
    this.setState({ loading: true });
    // console.log(this.props);
    try {
      let newValues = {
        ...values,

        category: this.props.dataItem.id,
        subscription: this.state.subscription,
        picture: this.state.picture,
        file: this.state.file,
        contentType: this.state.contentType,
        isRecommended: this.state.isRecommended,
      };
      await Content.addContent(newValues);

      //closing addNew Component
      this.props.toggleModal();

      //fetching data from dataBase
      this.props.dataListRender();

      // if (!this.state.isNew) {
      //   Swal.close();
      // }
      resetForm();
    } catch (error) {
      console.error(error);
      // toast(error.response.data.message);
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <>
        <Modal
          isOpen={this.props.modalOpen}
          toggle={this.props.toggleModal}
          wrapClassName="modal-right"
          backdrop="static"
        >
          {this.state.loading ? (
            <div class="loading_apge">
              <div class="pre_loader">
                <div class="loader2">
                  <div className="loading" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <ModalHeader toggle={this.props.toggleModal}>
            <IntlMessages id="pages.add-new-modal-content" />
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.initialValues}
              // validationSchema={this.ValidationSchema}
              onSubmit={this.handleSubmit}
            >
              {(props) => (
                <Form className="form" onSubmitCapture={props.handleSubmit}>
                  <Label className="mt-4">
                    <IntlMessages id="content-moti" />
                  </Label>
                  <Input onChange={this.handleChange} type="select">
                    <option value="Podcast">پادکست</option>;
                    <option value="Video">ویدئو</option>;
                    <option value="Book">کتاب</option>;
                  </Input>

                  <Label className="mt-4">
                    <IntlMessages id="advice-moti" />
                  </Label>
                  <Input onChange={this.handleChangeRec} type="select">
                    <option value={true}>بله</option>;
                    <option value={false}>خیر</option>;
                  </Input>

                  <Label className="mt-4">
                    <IntlMessages id="sub-moti" />
                  </Label>
                  <Input onChange={this.handleChangeSub} type="select">
                    {/* {console.log(this.state.data)} */}
                    {this.state.data &&
                      this.state.data.map((x) => {
                        return <option value={x.id}>{x.name}</option>;
                      })}
                  </Input>

                  <Label className="mt-4">
                    <IntlMessages id="name-moti" />
                  </Label>
                  <Field
                    name="name"
                    placeholder="نام  "
                    className="form-control"
                    type="text"
                    required
                  />
                  <Label className="mt-4">
                    <IntlMessages id="publisher-moti" />
                  </Label>
                  <Field
                    name="publisher"
                    placeholder="ناشر"
                    className="form-control"
                    type="text"
                    required
                  />

                  <Label className="mt-4">
                    <IntlMessages id="pic-moti" />
                  </Label>
                  <CustomInput
                    name="picture"
                    placeholder="عکس  "
                    type="file"
                    accept="image/*"
                    onChange={(e) => this.handleChangePicture(e)}
                    id="exampleCustomFileBrowser1"
                    // name="انتخاب تصویر"
                  />
                  {this.state.imagePreviewUrl ? (
                    <img
                      alt=""
                      style={{ width: "300px", marginTop: "20px" }}
                      src={this.state.imagePreviewUrl}
                    />
                  ) : (
                    ""
                  )}

                  <Label className="mt-4">
                    <IntlMessages id={this.state.src.toString()} />
                  </Label>
                  <CustomInput
                    name="file"
                    placeholder="فایل محتوا  "
                    type="file"
                    // accept="image/*"
                    onChange={(e) => this.handleChangeFile(e)}
                    id="exampleCustomFileBrowser1"
                    // name="انتخاب فایل"
                  />

                  {this.state.contentType === "Podcast" ? (
                    <>
                      <Label className="mt-4">
                        <IntlMessages id="time-moti" />
                      </Label>
                      <Field
                        name="duration"
                        placeholder="مدت زمان به روز"
                        className="form-control"
                        type="text"
                        required
                      />
                      <Label className="mt-4">
                        <IntlMessages id="description-moti" />
                      </Label>
                      <Field
                        name="description"
                        placeholder="توضیحات  "
                        className="form-control"
                        type="text"
                        required
                      />
                      <Label className="mt-4">
                        <IntlMessages id="announcer-moti" />
                      </Label>
                      <Field
                        name="announcer"
                        placeholder="گوینده  "
                        className="form-control"
                        type="text"
                        required
                      />
                    </>
                  ) : this.state.contentType === "Video" ? (
                    <>
                      <Label className="mt-4">
                        <IntlMessages id="time-moti" />
                      </Label>
                      <Field
                        name="duration"
                        placeholder="مدت زمان "
                        className="form-control"
                        type="text"
                        required
                      />

                      <Label className="mt-4">
                        <IntlMessages id="description-moti" />
                      </Label>
                      <Field
                        name="description"
                        placeholder="توضیحات  "
                        className="form-control"
                        type="text"
                        required
                      />
                    </>
                  ) : this.state.contentType === "Book" ? (
                    <>
                      <Label className="mt-4">
                        <IntlMessages id=" pages-moti" />
                      </Label>
                      <Field
                        name="pages"
                        placeholder="تعداد صفحات  "
                        className="form-control"
                        type="number"
                        required
                      />
                      <Label className="mt-4">
                        <IntlMessages id="writer-moti" />
                      </Label>
                      <Field
                        name="writer"
                        placeholder="نویسنده  "
                        className="form-control"
                        type="text"
                        required
                      />
                      <Label className="mt-4">
                        <IntlMessages id="translator-moti" />
                      </Label>
                      <Field
                        name="translator"
                        placeholder="مترجم  "
                        className="form-control"
                        type="text"
                        required
                      />
                      <Label className="mt-4">
                        <IntlMessages id="editor-moti" />
                      </Label>
                      <Field
                        name="edition"
                        placeholder="چاپ  "
                        className="form-control"
                        type="text"
                        required
                      />
                    </>
                  ) : (
                    ""
                  )}

                  <ModalFooter>
                    <Button
                      color="secondary"
                      outline
                      onClick={this.props.toggleModal}
                    >
                      <IntlMessages id="pages.cancel" />
                    </Button>
                    <Button color="primary" type="submit">
                      <IntlMessages id="pages.submit" />
                    </Button>{" "}
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>

          {/* <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody>
          <Label className="mt-4">
            <IntlMessages id="pages.name" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.price" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.duration" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.category" />
          </Label>
          <Select
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix="react-select"
            name="form-field-name"
            options={categories}
          />
          <Label className="mt-4">
            <IntlMessages id="pages.description" />
          </Label>
          <Input type="textarea" name="text" id="exampleText" />
          <Label className="mt-4">
            <IntlMessages id="pages.status" />
          </Label>
          <CustomInput
            type="radio"
            id="exCustomRadio"
            name="customRadio"
            label="ON HOLD"
          />
          <CustomInput
            type="radio"
            id="exCustomRadio2"
            name="customRadio"
            label="PROCESSED"
          />
        </ModalBody> */}
          {/* <ModalFooter>
          <Button color="secondary" outline onClick={toggleModal}>
            <IntlMessages id="pages.cancel" />
          </Button>
          <Button color="primary" onClick={toggleModal}>
            <IntlMessages id="pages.submit" />
          </Button>{" "}
        </ModalFooter> */}
        </Modal>
      </>
    );
  }
}
