import React, { Component } from "react";
import {
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import { Formik, Field, Form } from "formik";

// import Select from "react-select";
// import CustomSelectInput from "../../../../../../components/common/CustomSelectInput";
import IntlMessages from "../../../../../../helpers/IntlMessages";

import { User, Education } from "../../../../../../api/Api";

export default class AddConstant extends Component {
  state = {
    education: "",
    data: "",
    file: "",
    sex: "مرد",
    src: "",
    loading: false,
    initialValues: {
      name: "",
      phoneNumber: "",
      sex: "مرد",
      education: "",
      picture: "",
      accepted: true,
    },
  };

  componentDidMount() {
    this.getEducation();
  }

  getEducation = async () => {
    const {
      data: { data },
    } = await Education.getAllEducation();

    this.setState({ data, education: data && data[0] && data[0].id });
  };

  handleChangeFile = async ({ target }) => {
    try {
      let file = target.files[0],
        src = target.value;
      // console.log("file", file);

      this.setState({ file, src });
    } catch (error) {}
  };

  handleChange = (e) => {
    this.setState({
      education: e.target.value,
    });
  };

  handleChangeSex = (e) => {
    this.setState({
      sex: e.target.value,
    });
  };

  handleSubmit = async (values, { resetForm, setSubmitting, setValues }) => {
    this.setState({ loading: true });
    try {
      let newValues = {
        ...values,
        education: this.state.education,
        sex: this.state.sex,

        picture: this.state.file,
      };
      await User.addUser(newValues);

      //closing addNew Component
      this.props.toggleModal();

      //fetching data from dataBase
      this.props.dataListRender();

      // if (!this.state.isNew) {
      //   Swal.close();
      // }
      resetForm();
    } catch (error) {
      console.error(error);
      // toast(error.response.data.message);
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <>
        <Modal
          isOpen={this.props.modalOpen}
          toggle={this.props.toggleModal}
          wrapClassName="modal-right"
          backdrop="static"
        >
          {this.state.loading ? <div className="loading" /> : ""}
          <ModalHeader toggle={this.props.toggleModal}>
            <IntlMessages id="pages.add-new-modal-user" />
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.initialValues}
              // validationSchema={this.ValidationSchema}
              onSubmit={this.handleSubmit}
            >
              {(props) => (
                <Form className="form" onSubmitCapture={props.handleSubmit}>
                  <Label className="mt-4">
                    <IntlMessages id="name-moti" />
                  </Label>
                  <Field
                    name="name"
                    placeholder="نام"
                    className="form-control"
                    type="text"
                    required
                  />
                  <Label className="mt-4">
                    <IntlMessages id="phoneNumber-moti" />
                  </Label>
                  <Field
                    name="phoneNumber"
                    placeholder="شماره همراه"
                    className="form-control"
                    type="text"
                    required
                  />
                  <Label className="mt-4">
                    <IntlMessages id="sex-moti" />
                  </Label>
                  <Input onChange={this.handleChangeSex} type="select">
                    <option value="مرد">مرد</option>;
                    <option value="زن">زن</option>;
                  </Input>
                  {/* <FormGroup> */}
                  <Label className="mt-4">
                    <IntlMessages id="degree-moti" />
                  </Label>
                  <Input onChange={this.handleChange} type="select">
                    {this.state.data &&
                      this.state.data.map((x) => {
                        return <option value={x.id}>{x.name}</option>;
                      })}
                  </Input>
                  {/* </FormGroup> */}

                  <Label className="mt-4">
                    <IntlMessages id="pic-moti" />
                  </Label>
                  <CustomInput
                    name="picture"
                    placeholder="عکس  "
                    type="file"
                    accept="image/*"
                    onChange={(e) => this.handleChangeFile(e)}
                    id="exampleCustomFileBrowser1"
                    // name="customFile"
                  />
                  {this.state.imagePreviewUrl ? (
                    <img
                      alt=""
                      style={{ width: "300px", marginTop: "20px" }}
                      src={this.state.imagePreviewUrl}
                    />
                  ) : (
                    ""
                  )}
                  <ModalFooter>
                    <Button
                      color="secondary"
                      outline
                      onClick={this.props.toggleModal}
                    >
                      <IntlMessages id="pages.cancel" />
                    </Button>
                    <Button color="primary" type="submit">
                      <IntlMessages id="pages.submit" />
                    </Button>{" "}
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>

          {/* <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody>
          <Label className="mt-4">
            <IntlMessages id="pages.name" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.price" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.duration" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.category" />
          </Label>
          <Select
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix="react-select"
            name="form-field-name"
            options={categories}
          />
          <Label className="mt-4">
            <IntlMessages id="pages.description" />
          </Label>
          <Input type="textarea" name="text" id="exampleText" />
          <Label className="mt-4">
            <IntlMessages id="pages.status" />
          </Label>
          <CustomInput
            type="radio"
            id="exCustomRadio"
            name="customRadio"
            label="ON HOLD"
          />
          <CustomInput
            type="radio"
            id="exCustomRadio2"
            name="customRadio"
            label="PROCESSED"
          />
        </ModalBody> */}
          {/* <ModalFooter>
          <Button color="secondary" outline onClick={toggleModal}>
            <IntlMessages id="pages.cancel" />
          </Button>
          <Button color="primary" onClick={toggleModal}>
            <IntlMessages id="pages.submit" />
          </Button>{" "}
        </ModalFooter> */}
        </Modal>
      </>
    );
  }
}
