import { APIClient } from "./_api";

const endpoint = "/User";

export function getAllUser() {
  return APIClient.get(`${endpoint}`);
}

export function getUserById(id) {
  return APIClient.get(`UserById/${id}`);
}

export function addUser(data) {
  let bodyFormData = new FormData();
  for (let key in data) {
    bodyFormData.append(key, data[key]);
  }
  return APIClient.post(`${endpoint}`, bodyFormData);
}

export function editUser(data, id) {
  let bodyFormData = new FormData();
  for (let key in data) {
    bodyFormData.append(key, data[key]);
  }
  return APIClient.put(`${endpoint}/${id}`, bodyFormData);
}

export function deleteUser(id) {
  return APIClient.delete(`${endpoint}/${id}`);
}

export function acceptUser(id) {
  return APIClient.put(`/acceptUser/${id}`);
}

export function subUser(id, subId) {
  return APIClient.put(`/giveSubscription/${id}`, subId);
}

export function getAllBanUser() {
  return APIClient.get(`/deletedUsers`);
}

export function unbanUser(id) {
  return APIClient.put(`/restore/${id}`);
}
