import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Education from "./Education";
import Content from "./Content";
import ContentNotTree from "./ContentNotTree";
import Category from "./Category";
import Slider from "./Slider";
import User from "./User/index";
import BanedUser from "./BanedUser";
import Subscription from "./Subscription";
import thumbListPages from "./thumb-list";
import imageListPages from "./image-list";
import detailsPages from "./details";
import searchPages from "./search";
import mailingPages from "./mailing";
import invoicePages from "./invoice";

const Pages = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/Education`} />
      <Route path={`${match.url}/Education`} component={Education} />
      <Route path={`${match.url}/Content`} component={Content} />
      <Route path={`${match.url}/ContentNotTree`} component={ContentNotTree} />
      <Route path={`${match.url}/Subscription`} component={Subscription} />
      <Route path={`${match.url}/Category`} component={Category} />
      <Route path={`${match.url}/Slider`} component={Slider} />
      <Route path={`${match.url}/User`} component={User} />
      <Route path={`${match.url}/BanedUser`} component={BanedUser} />
      <Route path={`${match.url}/thumb-list`} component={thumbListPages} />
      <Route path={`${match.url}/image-list`} component={imageListPages} />
      <Route path={`${match.url}/details`} component={detailsPages} />
      <Route path={`${match.url}/search`} component={searchPages} />
      <Route path={`${match.url}/mailing`} component={mailingPages} />
      <Route path={`${match.url}/invoice`} component={invoicePages} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Pages;
