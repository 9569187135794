import React from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";

const Alert = ({ modal, toggle, acceptUser }) => (
  <>
    <Modal isOpen={modal} toggle={toggle}>
      {/* <ModalHeader toggle={toggle}>
        <IntlMessages id="modal.modal-title" />
      </ModalHeader> */}
      <ModalBody>آیا از تایید این مورد اطمینان دارید؟</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={acceptUser}>
          بله
        </Button>
        <Button color="secondary" onClick={toggle}>
          خیر
        </Button>
      </ModalFooter>
    </Modal>
  </>
);
export default Alert;
