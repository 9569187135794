import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";

// import axios from "axios";

// import { servicePath } from "../../../../../constants/defaultValues";

// import DataListView from "../../../../../containers/pages/DataListView";
import Pagination from "../../../../../containers/pages/Pagination";
import ContextMenuContainer from "../../../../../containers/pages/ContextMenuContainer";
import ImageListView from "../../../../../containers/pages/ImageListView";
import ThumbListView from "../../../../../containers/pages/ThumbListView";

import ListPageHeading from "../components/listPageHeading/index.js";
import AddNewModal from "../components/add";
import DataListView from "../components/view";

import { Content, Category } from "../../../../../api/Api";

function collect(props) {
  return { data: props.data };
}
// const apiUrl = servicePath + "/cakes/paging";

class MYData extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require("mousetrap");
  }

  state = {
    displayMode: "list",
    data: [],

    selectedPageSize: 10,
    orderOptions: [
      { column: "Podcast", label: "پادکست ها" },
      { column: "Video", label: "ویدئو ها" },
      { column: "Book", label: "کتاب ها" },
    ],
    pageSizes: [10, 20, 30, 50, 100],

    orderCategory: [],
    selectedCategory: [],

    selectedOrderOption: { column: "Podcast", label: "پادکست ها" },
    dropdownSplitOpen: false,
    modalOpen: false,
    currentPage: 1,
    totalItemCount: 0,
    totalPage: 1,
    search: "",
    selectedItems: [],
    lastChecked: null,
    isLoading: false,
  };

  componentDidMount() {
    this.getCategory();
    // this.dataListRender();
    this.mouseTrap.bind(["ctrl+a", "command+a"], () =>
      this.handleChangeSelectAll(false)
    );
    this.mouseTrap.bind(["ctrl+d", "command+d"], () => {
      this.setState({
        selectedItems: [],
      });
      return false;
    });
  }

  componentWillUnmount() {
    this.mouseTrap.unbind("ctrl+a");
    this.mouseTrap.unbind("command+a");
    this.mouseTrap.unbind("ctrl+d");
    this.mouseTrap.unbind("command+d");
  }

  getCategory = async () => {
    const {
      data: { data },
    } = await Category.getAllCategory();
    this.dataListRender(
      data && data.length && data[0],
      this.state.selectedOrderOption
    );
    this.setState({
      orderCategory: data && data.length && data,
      selectedCategory: data && data.length && data[0],
    });
  };

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  changeOrderBy = async (column) => {
    await this.setState(
      {
        selectedOrderOption: this.state.orderOptions.find(
          (x) => x.column === column
        ),
      },
      () =>
        this.dataListRender(
          this.state.selectedCategory,
          this.state.selectedOrderOption
        )
    );
  };
  changeCategory = async (column) => {
    await this.setState(
      {
        selectedCategory: this.state.orderCategory.find((x) => x.id === column),
      },
      () =>
        this.dataListRender(
          this.state.selectedCategory,
          this.state.selectedOrderOption
        )
    );
  };
  changePageSize = (size) => {
    this.setState(
      {
        selectedPageSize: size,
        currentPage: 1,
      },
      () =>
        this.dataListRender(
          this.state.selectedCategory,
          this.state.selectedOrderOption
        )
    );
  };
  changeDisplayMode = (mode) => {
    this.setState({
      displayMode: mode,
    });
    return false;
  };
  onChangePage = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () =>
        this.dataListRender(
          this.state.selectedCategory,
          this.state.selectedOrderOption
        )
    );
  };

  onSearchKey = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          search: e.target.value.toLowerCase(),
        },
        () =>
          this.dataListRender(
            this.state.selectedCategory,
            this.state.selectedOrderOption
          )
      );
    }
  };

  onCheckItem = (event, id) => {
    if (
      event.target.tagName === "A" ||
      (event.target.parentElement && event.target.parentElement.tagName === "A")
    ) {
      return true;
    }
    if (this.state.lastChecked === null) {
      this.setState({
        lastChecked: id,
      });
    }

    let selectedItems = this.state.selectedItems;
    if (selectedItems.includes(id)) {
      selectedItems = selectedItems.filter((x) => x !== id);
    } else {
      selectedItems.push(id);
    }
    this.setState({
      selectedItems,
    });

    if (event.shiftKey) {
      var items = this.state.items;
      var start = this.getIndex(id, items, "id");
      var end = this.getIndex(this.state.lastChecked, items, "id");
      items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
      selectedItems.push(
        ...items.map((item) => {
          return item.id;
        })
      );
      selectedItems = Array.from(new Set(selectedItems));
      this.setState({
        selectedItems,
      });
    }
    document.activeElement.blur();
  };

  getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1;
  }
  handleChangeSelectAll = (isToggle) => {
    if (this.state.selectedItems.length >= this.state.items.length) {
      if (isToggle) {
        this.setState({
          selectedItems: [],
        });
      }
    } else {
      this.setState({
        selectedItems: this.state.items.map((x) => x.id),
      });
    }
    document.activeElement.blur();
    return false;
  };

  dataListRender = async (category, type) => {
    // const {
    //   selectedPageSize,
    //   currentPage,
    //   selectedOrderOption,
    //   search,
    // } = this.state;

    try {
      const { data } = await Content.getAllContent();
      this.setState({
        // totalPage: data.totalPage,
        items: data.data,
        selectedItems: [],
        // totalItemCount: data.totalItem,
        isLoading: true,
      });
    } catch (error) {
      console.log(error);
    }
    // axios
    //   .get(
    //     `${apiUrl}?pageSize=${selectedPageSize}&currentPage=${currentPage}&orderBy=${selectedOrderOption.column}&search=${search}`
    //   )
    //   .then((res) => {
    //     return res.data;
    //   })
    //   .then((data) => {
    //     this.setState({
    //       totalPage: data.totalPage,
    //       items: data.data,
    //       selectedItems: [],
    //       totalItemCount: data.totalItem,
    //       isLoading: true,
    //     });
    //   });
  };

  onContextMenuClick = (e, data, target) => {
    console.log(
      "onContextMenuClick - selected items",
      this.state.selectedItems
    );
    console.log("onContextMenuClick - action : ", data.action);
  };

  onContextMenu = (e, data) => {
    const clickedProductId = data.data;
    if (!this.state.selectedItems.includes(clickedProductId)) {
      this.setState({
        selectedItems: [clickedProductId],
      });
    }

    return true;
  };

  render() {
    const {
      currentPage,
      items,
      displayMode,
      selectedPageSize,
      totalItemCount,
      selectedOrderOption,
      selectedCategory,
      selectedItems,
      orderOptions,
      orderCategory,
      pageSizes,
      modalOpen,
      categories,
    } = this.state;
    const { match } = this.props;
    const startIndex = (currentPage - 1) * selectedPageSize;
    const endIndex = currentPage * selectedPageSize;

    return !this.state.isLoading ? (
      <div className="loading" />
    ) : (
      <Fragment>
        <div className="disable-text-selection">
          <ListPageHeading
            heading="menu.content"
            displayMode={displayMode}
            changeDisplayMode={this.changeDisplayMode}
            handleChangeSelectAll={this.handleChangeSelectAll}
            changeOrderBy={this.changeOrderBy}
            changeCategory={this.changeCategory}
            changePageSize={this.changePageSize}
            selectedPageSize={selectedPageSize}
            totalItemCount={totalItemCount}
            selectedOrderOption={selectedOrderOption}
            selectedCategory={selectedCategory}
            match={match}
            startIndex={startIndex}
            endIndex={endIndex}
            selectedItemsLength={selectedItems ? selectedItems.length : 0}
            itemsLength={items ? items.length : 0}
            onSearchKey={this.onSearchKey}
            orderOptions={orderOptions}
            orderCategory={orderCategory}
            pageSizes={pageSizes}
            toggleModal={this.toggleModal}
          />
          <AddNewModal
            modalOpen={modalOpen}
            toggleModal={this.toggleModal}
            dataListRender={this.dataListRender}
            categories={categories}
          />

          <Row>
            {this.state.items && this.state.items.length
              ? this.state.items.map((product) => {
                  if (this.state.displayMode === "imagelist") {
                    return (
                      <ImageListView
                        key={product.id}
                        product={product}
                        isSelect={this.state.selectedItems.includes(product.id)}
                        collect={collect}
                        onCheckItem={this.onCheckItem}
                      />
                    );
                  } else if (this.state.displayMode === "thumblist") {
                    return (
                      <ThumbListView
                        key={product.id}
                        product={product}
                        isSelect={this.state.selectedItems.includes(product.id)}
                        collect={collect}
                        onCheckItem={this.onCheckItem}
                      />
                    );
                  } else {
                    return (
                      <DataListView
                        key={product.id}
                        product={product}
                        isSelect={this.state.selectedItems.includes(product.id)}
                        onCheckItem={this.onCheckItem}
                        collect={collect}
                        selectedCategory={selectedCategory}
                        selectedOrderOption={selectedOrderOption}
                        dataListRender={this.dataListRender}
                      />
                    );
                  }
                })
              : "محتوایی موجود نمیباشد "}{" "}
            <Pagination
              currentPage={this.state.currentPage}
              totalPage={this.state.totalPage}
              onChangePage={(i) => this.onChangePage(i)}
            />
            <ContextMenuContainer
              onContextMenuClick={this.onContextMenuClick}
              onContextMenu={this.onContextMenu}
            />
          </Row>
        </div>
      </Fragment>
    );
  }
}
export default MYData;
