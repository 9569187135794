import React, { Component } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { loginUser } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

import { account } from "../../api/Api";
import { setAuthToken } from "../../api/_api";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
    };
  }
  onUserLogin = async () => {
    if (this.state.userName !== "" && this.state.password !== "") {
      let value = {
        userName: this.state.userName,
        password: this.state.password,
      };
      // console.log(value);
      const {
        data: { data },
      } = await account.login(value);
      setAuthToken(data.token);
      localStorage.setItem("auth", data.token);
      // localStorage.setItem("floraUser", encode(data));

      // this go to dashboar
      // this.props.history.push("/app/dashboards/default");

      this.props.history.push("/app/pages/Education");
      this.props.loginUser(value, this.props.history);
    }
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side ">
              <p className="text-white h2">به پنل اوا خوش امدید</p>
              <p className="white mb-0">
                {/* Please use your credentials to login.
                <br />
                If you are not a member, please{" "} */}
                {/* <NavLink to={`/register`} className="white">
                  register
                </NavLink> */}
              </p>
            </div>
            <div className="form-side">
              <NavLink to={`/`} className="white">
                <span className="logo-single" />
              </NavLink>
              <CardTitle className="mb-4">
                <IntlMessages id="user.login-title" />
              </CardTitle>
              <Form>
                <Label className="form-group has-float-label mb-4">
                  <Input
                    onChange={this.handleChange}
                    // type="userName"
                    name="userName"
                    defaultValue={this.state.userName}
                  />
                  <IntlMessages id="user.username" />
                </Label>
                <Label className="form-group has-float-label mb-4">
                  <Input
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                  />
                  <IntlMessages
                    id="user.password"
                    defaultValue={this.state.password}
                  />
                </Label>
                <div className="d-flex justify-content-between align-items-center">
                  {/* <NavLink to={`/forgot-password`}>
                    <IntlMessages id="user.forgot-password-question" />
                  </NavLink> */}
                  <Button
                    color="primary"
                    className="btn-shadow"
                    size="lg"
                    onClick={this.onUserLogin}
                  >
                    <IntlMessages id="user.login-button" />
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(mapStateToProps, {
  loginUser,
})(Login);
