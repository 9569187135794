import React from "react";
import { TreeListCell } from "@progress/kendo-react-treelist";

export default function MyCommandCell(
  enterEdit,
  remove,
  save,
  cancel,
  addChild,
  editField,
  thiss,
  toggleModal,
  toggle,
  toggleEditModal,
  toggleContentModal
) {
  return class extends TreeListCell {
    state = { ok: false };
    render() {
      // console.log(thiss.state.lastChildId);

      const { dataItem } = this.props;

      return dataItem[editField] ? (
        <td>
          <button className="k-button" onClick={() => save(dataItem)}>
            <i
              title="تایید"
              className="fa fa-check font-medium-3 mr-1 ml-1 success"
            ></i>
          </button>
          <button className="k-button" onClick={() => cancel(dataItem)}>
            <i
              title="بازگشت"
              className="fa fa-times font-medium-3 mr-1 ml-1 danger"
            ></i>
          </button>
        </td>
      ) : (
        <td>
          {/* {thiss.state.lastChildId.find(
            x =>
              x.id === dataItem.id && (
                <button className="k-button" onClick={() => addChild(dataItem)}>
                  Add course
                </button>
              )
          )} */}

          <button
            title="اضافه کردن زیرمجموعه"
            className="k-button"
            onClick={() => toggleModal(dataItem)}
          >
            <i className="simple-icon-plus   btn btn-outline-info"></i>
          </button>
          <button
            title="ویرایش"
            className="k-button"
            onClick={() => toggleEditModal(dataItem)}
          >
            <i className="simple-icon-pencil  btn btn-outline-primary"></i>
          </button>
          <button
            title="پاک کردن"
            className="k-button"
            onClick={() => toggle(dataItem)}
          >
            <i className="simple-icon-trash simple-icon-trash  btn btn-outline-danger"></i>
          </button>
          {dataItem && !dataItem.child ? (
            // <div
            //   Style={{
            //     width: "200px",
            //   }}
            // >
            <button
              className="k-button"
              onClick={() => toggleContentModal(dataItem)}
            >
              <i
                title="اضافه کردن محتوا"
                className="btn-outline-success btn iconsminds-folder-add--"
              ></i>
              {/* <i className="simple-icon-plus simple-icon-plus  mb-2 mr-2 btn btn-outline-danger"></i> */}
            </button>
          ) : (
            // </div>
            ""
          )}
        </td>
      );
    }
  };
}
