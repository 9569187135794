import { APIClient } from "./_api";

const endpoint = "/Subscription";

export function getAllSubscription() {
  return APIClient.get(`${endpoint}`);
}

export function addSubscription(data) {
  return APIClient.post(`${endpoint}`, data);
}

export function editSubscription(data, id) {
  return APIClient.put(`${endpoint}/${id}`, data);
}

export function deleteSubscription(id) {
  return APIClient.delete(`${endpoint}/${id}`);
}
