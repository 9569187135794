import { APIClient } from "./_api";

const endpoint = "/Content";

export function getAllContent() {
  return APIClient.get(`${endpoint}`);
}

export function getAllContentByCategoryGroupBy(categoryId, type) {
  return APIClient.get(
    `ContentGroupBy/${categoryId ? categoryId : ""}?Type=${type}`
  );
}

export function addContent(data) {
  let bodyFormData = new FormData();
  for (let key in data) {
    bodyFormData.append(key, data[key]);
  }
  return APIClient.post(`${endpoint}`, bodyFormData);
}

export function editContent(data, id) {
  let bodyFormData = new FormData();
  for (let key in data) {
    bodyFormData.append(key, data[key]);
  }
  return APIClient.put(`${endpoint}/${id}`, bodyFormData);
}

export function deleteContent(id) {
  return APIClient.delete(`${endpoint}/${id}`);
}
