import React, { Component } from "react";

import {
  Card,
  // CustomInput,
  // Badge,
  // Button,
  // Modal,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import Alert from "../../../../../../components/common/Alert";
// import { Colxx } from "../../components/common/CustomBootstrap";
import { Colxx } from "../../../../../../components/common/CustomBootstrap";
// import IntlMessages from "../../../../../../helpers/IntlMessages";
import EditModal from "../edit";

import { Slider } from "../../../../../../api/Api";

export default class DataListView extends Component {
  state = {
    modalEditOpen: false,

    modal: false,
    modalRight: false,
    modalLarge: false,
    modalSmall: false,
    modalLong: false,
    modalBack: false,
    backdrop: true,
  };
  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleEditModal = () => {
    this.setState({
      modalEditOpen: !this.state.modalEditOpen,
    });
  };

  deleteItem = async (id) => {
    try {
      await Slider.deleteSlider(id);
      await this.props.dataListRender();
      this.toggle();
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const { product, isSelect, collect, onCheckItem } = this.props;
    return (
      <>
        <Colxx xxs="12" className="mb-3">
          <ContextMenuTrigger id="menu_id" data={product.id} collect={collect}>
            <Card
              onClick={(event) => onCheckItem(event, product.id)}
              className={classnames("d-flex flex-row", {
                active: isSelect,
              })}
            >
              <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                  <NavLink to={`?p=${product.id}`} className="w-40 w-sm-100">
                    <p className="list-item-heading mb-1 truncate">
                      {product.title}
                    </p>
                  </NavLink>
                  <p className="mb-1 text-muted text-small w-15 w-sm-100">
                    {product.description}
                  </p>
                  <p className="mb-1 text-muted text-small w-15 w-sm-100">
                    <img
                      alt=""
                      style={{ width: "100px" }}
                      src={product.picture}
                    />
                  </p>
                  {/* <div className="w-15 w-sm-100">
                    <Badge color={product.statusColor} pill>
                      {product.status}
                    </Badge>
                  </div> */}
                </div>
                {/* <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
              <CustomInput
                className="item-check mb-0"
                type="checkbox"
                id={`check_${product.id}`}
                checked={isSelect}
                onChange={() => {}}
                label=""
              />
            </div> */}
                <div className="custom-control custom-checkbox align-self-center pr-4 ">
                  {/* <Button color="primary" outline onClick={this.toggle}>
                    <IntlMessages id="modal.launch-demo-modal" />
                  </Button> */}
                  <Alert
                    modal={this.state.modal}
                    toggle={this.toggle}
                    deleteItem={() => this.deleteItem(product.id)}
                  />
                  <EditModal
                    modalOpen={this.state.modalEditOpen}
                    toggleModal={this.toggleEditModal}
                    dataListRender={this.props.dataListRender}
                    product={product}
                  />
                  <i
                    onClick={this.toggle}
                    className="simple-icon-trash  mb-2 mr-2 btn btn-outline-danger"
                  />
                  {/* <i
                    onClick={this.toggleEditModal}
                    className="simple-icon-pencil  mb-2 btn btn-outline-primary"
                  /> */}
                </div>
              </div>
            </Card>
          </ContextMenuTrigger>
        </Colxx>
      </>
    );
  }
}

// /* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
// export default React.memo(DataListView);
