import React, { useState } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";

export default ({
  modal,
  toggle,
  subUser,
  subscription,
  handleChange,
  thisUser,
  subscriptionId,
}) => {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <IntlMessages
          id={`نوع اشتراک فعلی :${
            thisUser && thisUser.subscription && thisUser.subscription.name
          }`}
        />
      </ModalHeader>
      <ModalBody>
        <Label className="mt-4">
          <IntlMessages id="sub-moti" />
        </Label>
        {/* {console.log("subscriptionId", subscriptionId)} */}
        <Input
          onChange={handleChange}
          type="select"
          defaultValue={subscriptionId && subscriptionId}
        >
          {subscription &&
            subscription.map((x) => {
              return <option value={x.id}>{x.name}</option>;
            })}
        </Input>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={subUser}>
          بله
        </Button>
        <Button color="secondary" onClick={toggle}>
          خیر
        </Button>
      </ModalFooter>
    </Modal>
  );
};
