import { APIClient } from "./_api";

const endpoint = "/Education";

export function getAllEducation() {
  return APIClient.get(`${endpoint}`);
}

export function addEducation(data) {
  return APIClient.post(`${endpoint}`, data);
}

export function editEducation(data, id) {
  return APIClient.put(`${endpoint}/${id}`, data);
}

export function deleteEducation(id) {
  return APIClient.delete(`${endpoint}/${id}`);
}
