import React, { Component, Fragment } from "react";
import {
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import { Formik, Field, Form, FormikProps } from "formik";

import Select from "react-select";
import CustomSelectInput from "../../../../../../components/common/CustomSelectInput";
import IntlMessages from "../../../../../../helpers/IntlMessages";

import { Subscription } from "../../../../../../api/Api";

export default class EditConstant extends Component {
  state = {
    loading: false,
    initialValues: {
      name: this.props.product.name,
      color: this.props.product.color,
      duration: this.props.product.duration,
      price: this.props.product.price,
      priority: this.props.product.priority,
    },
  };

  handleSubmit = async (values, { resetForm, setSubmitting, setValues }) => {
    this.setState({ loading: true });
    try {
      await Subscription.editSubscription(values, this.props.product.id);

      //closing addNew Component
      this.props.toggleModal();

      //fetching data from dataBase
      this.props.dataListRender();

      // if (!this.state.isNew) {
      //   Swal.close();
      // }
      resetForm();
    } catch (error) {
      console.error(error);
      // toast(error.response.data.message);
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <>
        <Modal
          isOpen={this.props.modalOpen}
          toggle={this.props.toggleModal}
          wrapClassName="modal-right"
          backdrop="static"
        >
          {this.state.loading ? <div className="loading" /> : ""}
          <ModalHeader toggle={this.props.toggleModal}>
            <IntlMessages id="pages.edit-new-modal-sub" />
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.initialValues}
              // validationSchema={this.ValidationSchema}
              onSubmit={this.handleSubmit}
            >
              {(props) => (
                <Form className="form" onSubmitCapture={props.handleSubmit}>
                  <Label className="mt-4">
                    <IntlMessages id="pages.name" />
                  </Label>
                  <Field
                    name="name"
                    placeholder="نام اشتراک"
                    className="form-control"
                    type="text"
                    required
                  />
                  <Label className="mt-4">
                    <IntlMessages id="color-moti" />
                  </Label>
                  <Field
                    name="color"
                    placeholder="کد رنگ"
                    className="form-control"
                    type="text"
                    required
                  />
                  <Label className="mt-4">
                    <IntlMessages id="prpriority-moti" />
                  </Label>
                  <Field
                    name="priority"
                    placeholder="اولویت"
                    className="form-control"
                    type="number"
                    required
                  />
                  <Label className="mt-4">
                    <IntlMessages id="time-moti" />
                  </Label>
                  <Field
                    name="duration"
                    placeholder="روز"
                    className="form-control"
                    type="text"
                    required
                  />

                  <Label className="mt-4">
                    <IntlMessages id="price-moti" />
                  </Label>
                  <Field
                    name="price"
                    placeholder="هزینه دوره"
                    className="form-control"
                    type="text"
                    required
                  />
                  <ModalFooter>
                    <Button
                      color="secondary"
                      outline
                      onClick={this.props.toggleModal}
                    >
                      <IntlMessages id="pages.cancel" />
                    </Button>
                    <Button color="primary" type="submit">
                      <IntlMessages id="pages.submit" />
                    </Button>{" "}
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>

          {/* <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody>
          <Label className="mt-4">
            <IntlMessages id="pages.name" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.price" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.duration" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.category" />
          </Label>
          <Select
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix="react-select"
            name="form-field-name"
            options={categories}
          />
          <Label className="mt-4">
            <IntlMessages id="pages.description" />
          </Label>
          <Input type="textarea" name="text" id="exampleText" />
          <Label className="mt-4">
            <IntlMessages id="pages.status" />
          </Label>
          <CustomInput
            type="radio"
            id="exCustomRadio"
            name="customRadio"
            label="ON HOLD"
          />
          <CustomInput
            type="radio"
            id="exCustomRadio2"
            name="customRadio"
            label="PROCESSED"
          />
        </ModalBody> */}
          {/* <ModalFooter>
          <Button color="secondary" outline onClick={toggleModal}>
            <IntlMessages id="pages.cancel" />
          </Button>
          <Button color="primary" onClick={toggleModal}>
            <IntlMessages id="pages.submit" />
          </Button>{" "}
        </ModalFooter> */}
        </Modal>
      </>
    );
  }
}
