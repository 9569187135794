import { APIClient } from "./_api";

const endpoint = "/Slider";

export function getAllSlider() {
  return APIClient.get(`${endpoint}`);
}

export function addSlider(data) {
  let bodyFormData = new FormData();
  for (let key in data) {
    bodyFormData.append(key, data[key]);
  }
  return APIClient.post(`${endpoint}`, bodyFormData);
}

// export function editSlider(data, id) {
//   return APIClient.put(`${endpoint}/${id}`, data);
// }

export function deleteSlider(id) {
  return APIClient.delete(`${endpoint}/${id}`);
}
