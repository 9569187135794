import React, { Component } from "react";
// import maper from "../../../../../../helpers/maper";

// import {
//   Card,
//   CustomInput,
//   Badge,
//   Button,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
// } from "reactstrap";
import {
  TreeList,
  TreeListToolbar,
  mapTree,
  extendDataItem,
  // TreeListBooleanEditor,
  // removeItems,
  modifySubItems,
  TreeListTextEditor,
} from "@progress/kendo-react-treelist";
import MyCommandCell from "../my-command-cell";

// import { NavLink } from "react-router-dom";
// import classnames from "classnames";
// import { ContextMenuTrigger } from "react-contextmenu";
import Alert from "../../../../../../components/common/Alert";
// import { Colxx } from "../../components/common/CustomBootstrap";
// import { Colxx } from "../../../../../../components/common/CustomBootstrap";
// import IntlMessages from "../../../../../../helpers/IntlMessages";
import EditModal from "../edit";
import AddNewModal from "../add";
import AddNewContent from "../addContent";

import { Category } from "../../../../../../api/Api";

const subItemsField = "child";
const expandField = "expanded";
const editField = "inEdit";

export default class DataListView extends Component {
  state = {
    data: [],
    expanded: [1, 2, 32],
    inEdit: [],
    myParentId: "",
    lastChild: [],
    lastChildId: [],
    modalEditOpen: false,
    modalContentOpen: false,
    modalOpen: false,
    dataItemEdit: "",
    dataItemContent: "",

    modal: false,
    modalRight: false,
    modalLarge: false,
    modalSmall: false,
    modalLong: false,
    modalBack: false,
    backdrop: true,
  };

  componentDidMount() {
    this.getAllVideoCat();
  }

  getAllVideoCat = async (size, page, query = "?") => {
    try {
      this.setState({ loading: true });

      const {
        data: { data },
      } = await Category.getAllCategoryTree();

      // var i;
      // for (i = 0; i < data.length; i++) {
      //   data[i].employees = data[i]["child"];
      //   delete data[i].child;
      // }

      this.setState((state) => ({
        ...state,
        data: data,
        // lastChildId
      }));
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });
  };

  addChild = async (dataItem) => {
    this.setState({ myParentId: dataItem.id });
    const newRecord = this.createNewItem();
    await this.setState({
      inEdit: [...this.state.inEdit, newRecord],
      expanded: [...this.state.expanded, dataItem.id],
      data: modifySubItems(
        this.state.data,
        subItemsField,
        (item) => item.id === dataItem.id,
        (subItems) => [newRecord, ...subItems]
      ),
    });
  };

  enterEdit = (dataItem) => {
    this.setState({
      inEdit: [...this.state.inEdit, extendDataItem(dataItem, subItemsField)],
    });
  };

  save = async (dataItem) => {
    let values = {
      name: dataItem.name,
      parentId: this.state.myParentId,
    };
    if (dataItem.isNew) {
      try {
        await Category.addCategory(values);
        this.getAllVideoCat();
        const { isNew, inEdit, ...itemToSave } = dataItem;
        this.setState({
          data: mapTree(this.state.data, subItemsField, (item) =>
            item.id === itemToSave.id ? itemToSave : item
          ),
          inEdit: this.state.inEdit.filter((i) => i.id !== itemToSave.id),
        });
      } catch (error) {
        // toast(error.response.data.message);
      }
    } else {
      try {
        await Category.editCategory(dataItem.id, values);
        this.getAllVideoCat();
        const { isNew, inEdit, ...itemToSave } = dataItem;
        this.setState({
          data: mapTree(this.state.data, subItemsField, (item) =>
            item.id === itemToSave.id ? itemToSave : item
          ),
          inEdit: this.state.inEdit.filter((i) => i.id !== itemToSave.id),
        });
      } catch (error) {
        // toast(error.response.data.message);
      }
    }
  };

  cancel = (editedItem) => {
    const { inEdit, data } = this.state;
    if (editedItem.isNew) {
      return this.remove(editedItem);
    }

    this.setState({
      data: mapTree(data, subItemsField, (item) =>
        item.id === editedItem.id ? inEdit.find((i) => i.id === item.id) : item
      ),
      inEdit: inEdit.filter((i) => i.id !== editedItem.id),
    });
  };

  // remove = async (dataItem) => {
  //   if (!dataItem.isNew) {
  //     SweetAlert({
  //       // title: "ایا از حذف این دسته بندی اطمینان دارید",
  //       html: (
  //         <div
  //           className="form-actions"
  //           style={{ background: "white", padding: "20px" }}
  //         >
  //           <h6 className="form-section">
  //             <i className="icon-book-open"></i>ایا از حذف این دسته بندی اطمینان
  //             دارید
  //           </h6>
  //           <button
  //             onClick={() => Swal.close()}
  //             type="button"
  //             className="btn btn-danger mr-1"
  //           >
  //             <i className="icon-trash"></i> لغو
  //           </button>
  //           <button
  //             onClick={async () => {
  //               try {
  //                 await Category.deleteCategory(dataItem.id);
  //                 this.getAllVideoCat();
  //                 this.setState({
  //                   data: removeItems(
  //                     this.state.data,
  //                     subItemsField,
  //                     (i) => i.id === dataItem.id
  //                   ),
  //                   inEdit: this.state.inEdit.filter(
  //                     (i) => i.id !== dataItem.id
  //                   ),
  //                 });
  //                 // toast("okok");
  //                 Swal.close();
  //               } catch (error) {
  //                 // toast(
  //                 //   "شما نمیتوانید کتگوری که دوره یا کتکوگوری مرتبط دارد را حذف کنبد",
  //                 //   "error"
  //                 // );
  //               }
  //             }}
  //             type="button"
  //             className="btn btn-success"
  //           >
  //             <i className="icon-note"></i> بله
  //           </button>
  //         </div>
  //       ),
  //     });
  //   } else {
  //     this.setState({
  //       data: removeItems(
  //         this.state.data,
  //         subItemsField,
  //         (i) => i.id === dataItem.id
  //       ),
  //       inEdit: this.state.inEdit.filter((i) => i.id !== dataItem.id),
  //     });
  //   }
  // };

  toggle = (dataItem) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    this.setState({ dataItem });
  };

  toggleEditModal = async (dataItem) => {
    await this.setState({ dataItemEdit: dataItem });
    this.setState({ modalEditOpen: !this.state.modalEditOpen });
  };

  toggleContentModal = async (dataItem) => {
    await this.setState({ dataItemContent: dataItem });
    this.setState({ modalContentOpen: !this.state.modalContentOpen });
  };

  toggleModal = (dataItem) => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      dataItem,
    });
  };

  CommandCell = MyCommandCell(
    this.enterEdit,
    this.remove,
    this.save,
    this.cancel,
    this.addChild,
    editField,
    this,
    this.toggleModal,
    this.toggle,
    this.toggleEditModal,
    this.toggleContentModal
  );

  onExpandChange = (e) => {
    this.setState({
      expanded: e.value
        ? this.state.expanded.filter((id) => id !== e.dataItem.id)
        : [...this.state.expanded, e.dataItem.id],
    });
  };

  onItemChange = (event) => {
    this.setState({
      data: mapTree(this.state.data, subItemsField, (item) =>
        item.id === event.dataItem.id
          ? extendDataItem(item, subItemsField, { [event.field]: event.value })
          : item
      ),
    });
  };

  addRecord = async (e) => {
    const newRecord = this.createNewItem();

    await this.setState({
      data: [newRecord, ...this.state.data],
      inEdit: [...this.state.inEdit, { ...newRecord }],
    });
  };

  createNewItem = () => {
    const timestamp = new Date().getTime();
    return { id: timestamp, isNew: true };
  };

  deleteItem = async (id) => {
    this.setState({ loading: true });
    try {
      await Category.deleteCategory(id);
      await this.getAllVideoCat();
      this.toggle();
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });
  };

  showImg = (dataItem) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        {dataItem.dataItem.picture ? (
          <img
            alt="عکس لود نشده است"
            style={{ width: "150px" }}
            src={dataItem.dataItem.picture}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  render() {
    // const { product, isSelect, collect, onCheckItem } = this.props;
    const { data, expanded, inEdit } = this.state;
    return (
      <>
        <Alert
          modal={this.state.modal}
          toggle={this.toggle}
          deleteItem={() =>
            this.deleteItem(this.state.dataItem.id && this.state.dataItem.id)
          }
        />
        <EditModal
          modalOpen={this.state.modalEditOpen}
          toggleModal={this.toggleEditModal}
          dataListRender={this.getAllVideoCat}
          dataItem={this.state.dataItemEdit && this.state.dataItemEdit}
        />
        <AddNewModal
          modalOpen={this.state.modalOpen}
          toggleModal={this.toggleModal}
          dataListRender={this.getAllVideoCat}
          // getData={this.getData}
          // categories={categories}
          dataItem={this.state.dataItem}
        />
        <AddNewContent
          modalOpen={this.state.modalContentOpen}
          toggleModal={this.toggleContentModal}
          dataListRender={this.getAllVideoCat}
          // getData={this.getData}
          // categories={categories}
          dataItem={this.state.dataItemContent && this.state.dataItemContent}
        />
        {/* {loading ? <Loader /> : ""} */}
        <TreeList
          style={{ maxHeight: "510px", overflow: "auto" }}
          data={mapTree(data ? data : [], subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
              [expandField]: expanded.includes(item.id),
              [editField]: Boolean(
                inEdit && inEdit.find((i) => i.id === item.id)
              ),
            })
          )}
          editField={editField}
          expandField={expandField}
          subItemsField={subItemsField}
          onItemChange={this.onItemChange}
          onExpandChange={this.onExpandChange}
          columns={[
            {
              field: "name",
              title: "نام",
              width: 280,
              editCell: TreeListTextEditor,
              expandable: true,
            },
            { cell: this.showImg, width: 400, title: "عکس" },

            { cell: this.CommandCell, width: 360 },
          ]}
          toolbar={
            <TreeListToolbar>
              {/* <button
                title="Add new"
                className="k-button k-primary"
                // onClick={this.addRecord}
                onClick={this.props.toggleModal}
              >
                اضافه کردن
              </button> */}
            </TreeListToolbar>
          }
        />
      </>
    );
  }
}

// /* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
// export default React.memo(DataListView);
