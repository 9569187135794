import { APIClient } from "./_api";

const endpoint = "/Category";

export function getAllCategory() {
  return APIClient.get(`${endpoint}`);
}

export function getAllCategoryTree() {
  return APIClient.get(`CategoryTree`);
}

export function addCategory(data) {
  let bodyFormData = new FormData();
  for (let key in data) {
    bodyFormData.append(key, data[key]);
  }
  return APIClient.post(`${endpoint}`, bodyFormData);
}

export function editCategory(data, id) {
  let bodyFormData = new FormData();
  for (let key in data) {
    bodyFormData.append(key, data[key]);
  }
  return APIClient.put(`${endpoint}/${id}`, bodyFormData);
}

export function deleteCategory(id) {
  return APIClient.delete(`${endpoint}/${id}`);
}
